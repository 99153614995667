import { defineStore } from 'pinia'
import type { components } from '~/interfaces/api/api'

type Course = components['schemas']['CourseDetail']

interface CourseWidgetCourse {
  course: Course
  isInModal: boolean
  freeLessonsDisabled: boolean
  lockedTarget: {
    type: 'modal' | 'page' | 'section'
    id: string
  }
}

interface CourseStore {
  list: Array<CourseWidgetCourse>
  highlightCourse: Course | null
}

export const useCourseStore = defineStore('course', {
  state: () => {
    return {
      list: [] as Array<CourseWidgetCourse>,
      highlightCourse: null as Course | null,
    } as CourseStore
  },
  actions: {
    async loadBySlug(slug: string, ctaTargetType: 'page' | 'modal', ctaTargetId: string, isInModal: boolean, freeLessonsDisabled: boolean) {
      if (typeof this.getCourseBySlug(slug) === 'undefined') {
        let course = null
        if (slug === 'course-of-the-week') {
          course = await useNuxtApp().$api.course.getCoursesCourseOfTheWeek()
          course.slug = 'course-of-the-week'
        }
        else {
          course = await useNuxtApp().$api.course.getCoursesSlug(slug)
        }

        const courseWidgetCourse = {
          course,
          isInModal,
          freeLessonsDisabled,
          lockedTarget: {
            type: ctaTargetType,
            id: ctaTargetId,
          },
        }

        this.list.push(courseWidgetCourse)
      }
    },
    async loadHighlightCourseBySlug(slug: string) {
      if (!this.highlightCourse) {
        const course = await useNuxtApp().$api.course.getCoursesSlug(slug)
        const lessons = await useNuxtApp().$api.course.getCoursesSlugLessons(slug)
        course.lessons = lessons
        this.highlightCourse = course
      }
    },
    navigateLockedCta(slug: string) {
      useCourseModalStore().closeModal()

      const course = this.getCourseBySlug(slug)
      if (course) {
        if (course.lockedTarget.type === 'page') {
          const localePath = useLocalePath()
          navigateTo(localePath(course.lockedTarget.id))
        }

        if (course.lockedTarget.type === 'section') {
          const section = document.getElementById(course.lockedTarget.id)
          section?.scrollIntoView({ behavior: 'smooth' })
        }

        if (course.lockedTarget.type === 'modal') { useModalStore().openModal(course.lockedTarget.id) }
      }
    },
  },
  getters: {
    getCourseBySlug: state => (slug: string) => {
      return state.list.find(course => course.course.slug === slug)
    },
  },
})
